import React from "react"
import { graphql } from "gatsby"

import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"

import { FaCheckCircle as CheckIcon } from "react-icons/fa"

import Layout from "../components/layout"
import SEO from "../components/seo"

import "./launch.module.scss"

function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&")
}

export default class Launch extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      submitted: false,
      submitting: false,
      formData: {},
    }
  }

  handleChange = e => {
    const name = e.target.name
    const value = e.target.value
    this.setState(oldState => {
      return {
        ...oldState,
        formData: {
          ...oldState.formData,
          [name]: value,
        },
      }
    })
  }

  handleSubmit = e => {
    e.preventDefault()
    const form = e.target
    this.setState({ submitting: true })
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": form.getAttribute("name"),
        ...this.state.formData,
      }),
    })
      .then(() => {
        this.setState({ submitted: true })
      })
      .catch(error => alert(error))
      .finally(() => {
        this.setState({ submitting: false })
      })
  }

  render() {
    const { data } = this.props
    const { submitted, submitting } = this.state

    return (
      <Layout>
        <SEO title="Launch" />
        <div styleName="outer-wrapper">
          <Container styleName="container">
            <div styleName="inner-wrapper">
              <div styleName="desc-parent">
                <h1>SHAPE Launch Form</h1>
                <p>
                  SHAPE Launch refers to a full-scale deployment of the SHAPE
                  service. This questionnaire will help us understand your
                  requirements for a launch.
                </p>
                <p>
                  Complete this form and we'll get in touch shortly with more
                  details.
                </p>
                <p style={{ marginBottom: 60 }}>* Required fields</p>
              </div>
              <form
                styleName="form-parent"
                name="launch"
                method="post"
                action="/thanks/"
                data-netlify="true"
                data-netlify-honeypot="bot-field"
                onSubmit={this.handleSubmit}
                disabled={submitted}
                id="launch-form"
              >
                <Row>
                  <Col sm={12}>
                    <div styleName="input-parent">
                      <label>Your name *</label>
                      <input
                        type="text"
                        id="launch-input-name"
                        required
                        name="Your name"
                        className="form-control"
                        onChange={this.handleChange}
                      />
                    </div>
                    <div styleName="input-parent">
                      <label>Your email address *</label>
                      <input
                        type="text"
                        required
                        id="launch-input-email"
                        name="Your email address"
                        className="form-control"
                        onChange={this.handleChange}
                      />
                    </div>
                    <div styleName="input-parent">
                      <label>Company name *</label>
                      <input
                        type="text"
                        required
                        id="launch-input-companyname"
                        name="Company name"
                        className="form-control"
                        onChange={this.handleChange}
                      />
                    </div>

                    <div styleName="input-parent">
                      <label styleName="customHeight">
                        Your office address *
                      </label>
                      <input
                        type="text"
                        required
                        id="launch-input-officeaddress"
                        name="Your office address"
                        className="form-control"
                        onChange={this.handleChange}
                      />
                    </div>

                    <div styleName="input-parent">
                      <label>Country/Countries to deploy to *</label>
                      <input
                        type="text"
                        required
                        id="launch-input-countries"
                        name="Country/Countries to deploy to"
                        className="form-control"
                        onChange={this.handleChange}
                      />
                    </div>

                    <div styleName="input-parent">
                      <label styleName="customHeight">
                        Name of signatory
                        <br />
                        <span>
                          This is the person who will be signing all contractual
                          documents.
                        </span>
                      </label>
                      <div>
                        <input
                          type="text"
                          id="launch-input-signatory"
                          name="Name of signatory"
                          className="form-control"
                          onChange={this.handleChange}
                        />
                      </div>
                    </div>

                    <div styleName="input-parent">
                      <label styleName="customHeight">
                        Name of CEO/Company head *
                        <br />
                        <span>
                          You will need to nominate a management figurehead
                          (e.g., CEO or Executive sponsor).
                        </span>
                      </label>
                      <input
                        type="text"
                        required
                        id="launch-input-ceoname"
                        name="Name of CEO/Company head"
                        className="form-control"
                        onChange={this.handleChange}
                      />
                    </div>

                    <div styleName="input-parent">
                      <label styleName="customHeight">
                        Email of the CEO/Company head *
                      </label>
                      <div>
                        <input
                          type="text"
                          required
                          id="launch-input-ceoemail"
                          name="Email of the CEO/Company head"
                          className="form-control"
                          onChange={this.handleChange}
                        />
                      </div>
                    </div>

                    <div styleName="input-parent">
                      <label styleName="customHeight">
                        Name of nominated SSA *
                        <br />
                        <span>
                          The SSA, or SHAPE Senior Administrator, is the SHAPE
                          Champion in your organisation. This person will work
                          with us to set up SHAPE for your company and
                          coordinate with us. This may be you, or a colleague.
                        </span>
                      </label>
                      <div>
                        <input
                          type="text"
                          required
                          id="launch-input-ssaname"
                          name="Name of nominated SSA"
                          className="form-control"
                          onChange={this.handleChange}
                        />
                      </div>
                    </div>

                    <div styleName="input-parent">
                      <label styleName="customHeight">
                        Email of nominated SSA *
                      </label>
                      <input
                        type="text"
                        required
                        id="launch-input-ssaemail"
                        name="Email of nominated SSA"
                        className="form-control"
                        onChange={this.handleChange}
                      />
                    </div>

                    <div styleName="input-parent">
                      <label styleName="customHeight">
                        Your PO number
                        <br />
                        <span>PO number is your purchase order number.</span>
                      </label>
                      <input
                        type="text"
                        id="launch-input-po-number"
                        name="Your PO number"
                        className="form-control"
                        onChange={this.handleChange}
                      />
                    </div>

                    <div styleName="input-parent">
                      <label styleName="customHeight">
                        Eligible employees in your organisation
                        <br />
                        <span>
                          This is the number of employees who will participate
                          in the SHAPE Survey.
                        </span>
                      </label>
                      <input
                        type="text"
                        id="launch-input-eligibleemployees"
                        name="Eligible employees in your organisation"
                        className="form-control"
                        onChange={this.handleChange}
                      />
                    </div>

                    <div styleName="input-parent">
                      <label styleName="customHeight">
                        Will your employees be comfortable completing the survey
                        in English?
                        <br />
                        <span>
                          The service is currently available in English. For
                          other languages,{" "}
                          <a href="/contact" target="_blank">
                            contact us
                          </a>
                          .
                        </span>
                      </label>
                      <div>
                        <label className="form-group">
                          <input
                            type="radio"
                            value="Yes"
                            id="launch-input-englishsurvey-yes"
                            styleName="radio-input"
                            name="Will your employees be comfortable completing the survey in English?"
                            onChange={this.handleChange}
                          />{" "}
                          Yes
                        </label>
                        <br />
                        <label className="form-group">
                          <input
                            type="radio"
                            styleName="radio-input"
                            id="launch-input-englishsurvey-no"
                            value="No"
                            name="Will your employees be comfortable completing the survey in English?"
                            onChange={this.handleChange}
                          />{" "}
                          No
                        </label>
                        <br />
                        <label className="form-group">
                          <input
                            type="radio"
                            id="launch-input-englishsurvey-maybe"
                            value="Maybe"
                            styleName="radio-input"
                            name="Will your employees be comfortable completing the survey in English?"
                            onChange={this.handleChange}
                          />{" "}
                          Maybe
                        </label>
                      </div>
                    </div>

                    <div styleName="input-parent">
                      <label styleName="customHeight">
                        Are you working with a SHAPE Distribution Partner, or
                        have you been referred by another company/person?
                        <br />
                        <span>
                          Knowing who you're working with lets us coordinate the
                          best experience for you.
                        </span>
                      </label>
                      <input
                        type="text"
                        id="launch-input-distributionpartner"
                        name="Are you working with a SHAPE Distribution Partner, or have you been referred by another company/person?"
                        className="form-control"
                        onChange={this.handleChange}
                      />
                    </div>
                  </Col>
                </Row>
                <div styleName="cta">
                  {submitted && (
                    <div styleName="submittedSuccess">
                      Thank you. We’ll be in touch very shortly.
                    </div>
                  )}
                  <button
                    type="submit"
                    id="launch-form-submit-button"
                    disabled={submitted || submitting}
                    className="text-white"
                  >
                    {(submitted && "Submitted") ||
                      (submitting && "Submitting...") ||
                      "Submit"}
                  </button>
                </div>
              </form>
            </div>
          </Container>
        </div>
      </Layout>
    )
  }
}

export const query = graphql`
  {
    allCountryJson {
      edges {
        node {
          value
          name
        }
      }
    }
    allPositionJson {
      edges {
        node {
          value
          name
        }
      }
    }
    allIndustryJson {
      edges {
        node {
          value
          name
        }
      }
    }
    allHowFindJson {
      edges {
        node {
          value
          name
        }
      }
    }
    allCompanySizeJson {
      edges {
        node {
          value
          name
        }
      }
    }
  }
`
